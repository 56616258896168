import React from "react";
import { connect } from "react-redux";
import { ReduxState } from "../../../../store/ReduxState";
import { FineSearcher } from "./FineSearcher";
import { Div } from "../../../../components/core/03-base/Div";

interface OwnProps {
    asPage: boolean;
}

interface StateProps {
}

type Props = StateProps & OwnProps;

/**
 *
 */
export const mapStateToProps = (_s: ReduxState): StateProps => ({
});

class HomeComp
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);
    }

    public render() {
        return (
            <Div
                theme={{
                    padding: {
                        "": { b: 3 },
                        "sm": { b: 4 },
                    },
                }}
            >
                <FineSearcher asPage={this.props.asPage} />
            </Div>
        );
    }
}

export const Home = connect(
    mapStateToProps,
    undefined,
)(HomeComp);
