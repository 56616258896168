import * as React from "react";

import { joinClasses } from "../../../utils/Filters";
import { ThemeConsumerProps } from "../../../theme/_Types";
import { asThemeConsumer } from "../../../theme/Theme";

/**
 *
 */
interface Props
    extends ThemeConsumerProps {
}

/**
 *
 * @param props
 */
const BlockquoteComp: React.FunctionComponent<Props> = (props) => {
    const clazzName = joinClasses(
        props.className || "",
        "scl-a-blockquote",
    );

    return !props.children
        ? null
        : (
            <div className={clazzName} style={props.style}>
                {props.children}
            </div>
        );
};

/**
 *
 */
export const Blockquote = asThemeConsumer(BlockquoteComp);
