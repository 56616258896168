import * as React from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import moment from "moment";

export interface DateInputProps extends Pick<ReactDatePickerProps, "popperPlacement"> {
    className?: string;
    value?: string | number;
    onChange?: (value?: string) => void;
    minDate?: Date;
    disabled?: boolean;
    required?: boolean;
}

type Props = DateInputProps;

const onChangeDate = (date?: Date | null, onChange?: (value?: string) => void) => {
    if (!!onChange) {
        onChange(
            date ? moment(date).format("YYYY-MM-DD") : undefined);
    }
};

/**
 *
 * @param props
 */
export const DateInput: React.FunctionComponent<Props> = (props) => (
    <DatePicker
        selected={!!props.value && typeof props.value === "string" ? new Date(props.value) : undefined}
        dateFormat={"dd-MM-yyyy"}
        onChange={(value: Date | null) => onChangeDate(value, props.onChange)}
        className={props.className}
        isClearable={!!props.disabled ? false : true}
        placeholderText={"DD-MM-YYYY"}
        popperPlacement={props.popperPlacement || "top"}
        minDate={props.minDate}
        disabled={!!props.disabled}
        required={props.required}
    />
);

export const testUtil = {
    onChangeDate: onChangeDate,
};
