import moment from "moment";
import * as React from "react";
import { ReactDatePickerProps } from "react-datepicker";

import { Currency } from "../../../components/core/05-atoms/CurrencyDisplay";
import { CurrencyInput } from "../../../components/core/05-atoms/CurrencyInput";
import { DateInput } from "../../../components/core/05-atoms/DateInput";
import { ThemeConsumerProps } from "../../../theme/_Types";
import { asThemeConsumer } from "../../../theme/Theme";
import { joinClasses } from "../../../utils/Filters";
import { formatLicensePlate, isValidLicensePlate } from "../../../utils/LicensePlate";
import { ensure } from "../../../utils/Optional";
import { LicensePlateInput } from "./LicenseplateInput";

/**
 *
 */
export interface InputProps
    extends ThemeConsumerProps, Pick<ReactDatePickerProps, "popperPlacement"> {
    placeholder?: string;
    type?: string;
    value?: string | number;
    onChange?: (value?: string) => void;
    disabled?: boolean;
    min?: number | string;
    max?: number | string;
    required?: boolean;
    asSummary?: boolean;
    minDate?: Date;
}

export const inputValue = (value?: string | number, type?: string, asSummary?: boolean) => {
    if ((type === "date" && !!asSummary) &&
        !!value && typeof value === "string"
    ) {
        const isMomentDate = moment(value, "YYYY-MM-DD");
        if (isMomentDate.isValid()) {
            const xyz = isMomentDate.format("DD-MM-YYYY");
            return xyz;
        } else {
            return "";
        }
    }

    if (type === "licensePlate" && !!asSummary) {
        const v = value === "NULL" || !value ? "" : value?.toString();
        return isValidLicensePlate(v)
            ? formatLicensePlate(v)
            : v?.toUpperCase();
    }
    return value || value === 0 ? value : "";
};

/**
 *
 * @param props
 */
const InputComp: React.FunctionComponent<InputProps> = (props) => {
    const clazzName = joinClasses(
        props.className,
        "scl-a-input__field",
    );

    const onChange = !!props.onChange
        ? (evt: React.ChangeEvent<HTMLInputElement>) => {
            evt.preventDefault();
            ensure(props.onChange)(evt.target.value || undefined);
        }
        : undefined;

    return (
        props.type === "currency" ? (
            !!props.asSummary ? (
                <Currency
                    className={clazzName}
                    amount={props.value}
                />) :
                (
                    <CurrencyInput
                        className={clazzName}
                        amount={props.value}
                        onChange={props.onChange}
                        isInput
                        placeholder={props.placeholder}
                        disabled={!!props.disabled}
                    />
                ))
            : props.type === "date" && !props.asSummary ?
                (
                    <DateInput
                        onChange={props.onChange}
                        className={clazzName}
                        value={props.value}
                        popperPlacement={props.popperPlacement}
                        minDate={props.minDate}
                        disabled={!!props.disabled}
                        required={props.required}
                    />
                )
                : (props.type === "licensePlate" && !props.asSummary) ?
                    (
                        <LicensePlateInput
                            value={inputValue(props.value, props.type, props.asSummary)}
                            onChange={props.onChange}
                            disabled={!!props.disabled}
                            required={props.required}
                            asSummary={props.asSummary}
                        />
                    )
                    : (
                        <input
                            type={props.type && !props.asSummary ? props.type : "text"}
                            className={clazzName}
                            placeholder={props.placeholder}
                            readOnly={!onChange}
                            value={inputValue(props.value, props.type, props.asSummary)}
                            onChange={onChange}
                            disabled={!!props.disabled}
                            min={props.min}
                            max={props.max}
                            required={props.required}
                        />)
    );
};

/**
 *
 */
export const Input = asThemeConsumer(InputComp);
