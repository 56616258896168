import "core-js";

import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import {
    BrowserRouter as Router, Route, RouteComponentProps, Switch,
} from "react-router-dom";

import { App } from "./App";
import { BaseConsumer, BaseProvider } from "./components/core/03-base/Base";
import { FlexWrapperFocus } from "./components/core/03-base/FlexWrapper";
import registerServiceWorker from "./serviceWorker";
import { createReduxStore } from "./store/ReduxStore";
import { Themes } from "./theme/_Types";
import { ErrorBoundary } from "./utils/Boundary";
import { MaryProvider } from "./utils/redux/MaryProvider";
import { VerifyContextWrapper } from "./App/utils/VerifyContextWrapper";
import { PreLoader } from "./components/core/03-base/Preloader";

const Root: React.FunctionComponent<{}> = ({}) => {
    const store = createReduxStore({
        apiEndpoint: process.env.REACT_APP_API_ENDPOINT || "",
    });
    return (
        <BaseProvider
            theme={Themes.CLAUDE}
            dynamic
        >
            <ErrorBoundary>
                <Provider store={store}>
                    <Router>
                        <MaryProvider flexDefaultFocus={FlexWrapperFocus.CONTENT} >
                            <VerifyContextWrapper>
                                <BaseConsumer>{({ setTheme }) =>
                                    (
                                        <Switch>
                                            <Route
                                                render={(p: RouteComponentProps ) => (
                                                    <App {...p} setTheme={setTheme} />
                                                )}
                                            />
                                        </Switch>
                                    )}
                                </BaseConsumer>
                            </VerifyContextWrapper>
                            <PreLoader />
                        </MaryProvider>
                    </Router>
                </Provider>
            </ErrorBoundary>
        </ BaseProvider>);
};

const elm = document.getElementById("root") as HTMLElement;
if (elm) {
    ReactDOM.render(
        <Root />,
        elm
    );
}

registerServiceWorker();
