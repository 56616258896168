import * as React from "react";

import { ThemeConsumerProps } from "../../../theme/_Types";
import { asThemeConsumer } from "../../../theme/Theme";
import { joinClasses } from "../../../utils/Filters";

/**
 * IconNames is an enum of Icons provided by Mary. The implementation relies
 * heavily on Scarlet, meaning that for the components to properly render, they
 * need to be present in the Scarlet theme being used.
 */
export enum IconNames {
    ARROW_LEFT = "arrow-left",
    ARROW_RIGHT = "arrow-right",
    BOLT = "bolt",
    DELETE = "trash",
    DOWNLOAD = "download",
    EDIT = "pencil-alt",
    FIRST = "angle-double-left",
    LAST = "angle-double-right",
    LOCK = "lock",
    MOVE = "arrows-alt",
    NEXT = "angle-right",
    OBJECT = "vector-square",
    PAPER_PLANE = "paper-plane",
    PLUS = "plus",
    POWER_OFF = "power-off",
    PREVIOUS = "angle-left",
    REDO = "redo",
    UNDO = "undo",
    SIGN_OUT = "sign-out-alt",
    SORT_DOWN = "sort-amount-down",
    SORT_UP = "sort-amount-up",
    TIMES = "times",
    VIEW = "eye",
    HIDDEN = "eye-slash",
    ZOOM_IN = "search-plus",
    ZOOM_OUT = "search-minus",
    NOTIFICATION_ALERT = "bell",
    MENU_ELLIPSIS = "ellipsis-v",
}

/**
 *
 */
interface Props
    extends ThemeConsumerProps {
    name?: IconNames | string;
    elementType?: string;
}

/**
 *
 * @param props
 */
const IconComp: React.FunctionComponent<Props> = (props) => {
    const iconClassName = props.name ? `fa fa-${props.name.toString().toLowerCase()}` : undefined;

    const className = joinClasses(
        props.className,
        iconClassName,
    );

    return React.createElement(
        props.elementType || "i",
        {
            className: className,
        },
        props.children,
    );
};

/**
 *
 */
export const Icon = asThemeConsumer(IconComp);
