import * as React from "react";
/**
 *
 * @param props
 */
export const NavbarIcon: React.FunctionComponent<{}> = (props) => (
    <div className="scl-o-navbar__nav">
        {props.children}
    </div>
);
