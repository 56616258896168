/* eslint-disable max-len */
import React from "react";
import { connect } from "react-redux";

import { Container } from "../../../../components/core/03-base/Container";
import { Div } from "../../../../components/core/03-base/Div";
import { Blockquote } from "../../../../components/core/05-atoms/Blockquote";
import { Button } from "../../../../components/core/05-atoms/Button";
import { Link, LinkTarget } from "../../../../components/core/05-atoms/Link";
import { LoadingIndications, LoadingIndicator } from "../../../../components/core/05-atoms/LoadingIndicator";
import { ButtonsWrapper } from "../../../../components/core/06-molecules/ButtonsWrapper";
import * as FormFields from "../../../../components/core/07-organisms/Form/Field";
import { hasLicensePlateValidation } from "../../../../components/core/07-organisms/Form/Field.utils";
import { hasValueValidation } from "../../../../components/core/07-organisms/Form/Field.utils";
import { Header } from "../../../../components/core/09-views/01-sections/Header";
import { DispatchFunc } from "../../../../store/ActionTypes";
import { FileDownload } from "../../../../store/FineSearcher/Types";
import { ReduxState, isRemoteLoading } from "../../../../store/ReduxState";
import { remoteClearError, remoteClearResponse, remoteTrigger } from "../../../../store/RemoteActions";
import { RemoteScope } from "../../../../store/RemoteTypes";
import { ThemePalette, ThemePaletteState, ThemeShadowSizes } from "../../../../theme/_Types";
import { formatLicensePlate, isValidLicensePlate, parseLicenseplate } from "../../../../utils/LicensePlate";
import * as I18n from "../../../../utils/translations/I18n";

interface State {
    licensePlate: string;
    dispositionNumber: string;
}

interface OwnProps {
    asPage: boolean;
}

interface StateProps {
    results?: FileDownload;
    loadingDamagePDF: boolean;
}

/**
 *
 */
interface DispatchProps {
    fileDownload: (licensePlate: string, dispositionNumber: string) => void;
    clearResults: () => void;
}

type Props = StateProps & DispatchProps & OwnProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    results: s.prop("remote").prop(RemoteScope.FINE_SEARCHER),
    loadingDamagePDF: isRemoteLoading(s, RemoteScope.FINE_SEARCHER),
});


/**
 *
 */
const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    fileDownload: (licensePlate: string, dispositionNumber: string) =>
        dispatch(remoteTrigger(RemoteScope.FINE_SEARCHER, {
            licensePlate: licensePlate,
            dispositionNumber: dispositionNumber,
        })),
    clearResults: () => (
        (
            dispatch(remoteClearResponse(RemoteScope.FINE_SEARCHER)),
            dispatch(remoteClearError(RemoteScope.FINE_SEARCHER))
        )),
});

class FineSearcherComp
    extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = { licensePlate: "", dispositionNumber: "" };
        this.clear = this.clear.bind(this);
    }

    public render() {
        return (
            <>
                <Container
                    theme={{
                        shadow: this.props.asPage ? ThemeShadowSizes.SMALL : undefined,
                        palette: this.props.asPage ? ThemePalette.CONTRAST_PRIMARY : undefined,
                        padding: {
                            "": { y: 3 },
                            "sm": { y: 4 },
                        },
                    }}
                >
                    <Header
                        title="Bekeuringzoeker"
                        description={
                            `U kunt hier uw bekeuring opzoeken, vul hieronder uw kenteken en het beschikkingsnummer in.
                            Hierna zullen wij u voorzien van de bekeuring die u kunt downloaden als PDF.`
                        }
                    />
                    {this.props.results?.statusCode === 200 && (
                        <>
                            <Div
                                theme={{
                                    padding: {
                                        "": { b: 2 },
                                        "sm": { b: 3 },
                                    },
                                }}
                            >
                                <b>
                                    <Blockquote
                                        style={{ fontWeight: 700 }}
                                    >
                                        {`Wij hebben uw bekeuring voor
                                        ${formatLicensePlate(this.state.licensePlate) ?? "-"}
                                        met beschikkingsnummer ${this.state.dispositionNumber} gevonden,
                                        u kunt de bekeuring nu downloaden.`}
                                    </Blockquote>
                                </b>
                            </Div>
                            <ButtonsWrapper>
                                <Button
                                    className="scl-a-btn--big"
                                    theme={{
                                        palette: ThemePalette.BRAND_ACCENT,
                                    }}
                                    link={{ href: this.props.results?.downloadUrl ?? "", target: LinkTarget.BLANK }}
                                >Download bekeuring</Button>
                                <Button
                                    theme={{
                                        palette: ThemePalette.CONTRAST_PRIMARY,
                                    }}
                                    link={{ onClick: this.clear }}
                                >Ga terug</Button>
                            </ButtonsWrapper>
                        </>
                    )}
                    {(this.props.results?.statusCode === 400 ||  this.props.results?.statusCode === 404 || this.props.results?.statusCode === 403) && (
                        <Div
                            theme={{
                                padding: {
                                    "": { b: 2 },
                                    "sm": { b: 3 },
                                },
                            }}
                        >
                            <Blockquote
                                theme={{
                                    paletteState: ThemePaletteState.DANGER,
                                }}
                                style={{ fontWeight: 700 }}
                            >
                                {this.props.results?.statusCode === 403 ? (
                                    <>
                                    Oeps er is iets misgegaan.
                                    Wij kunnen geen verbinding maken met de API.
                                    </>
                                ) : (
                                    <>
                                    Wij kunnen uw bekeuring niet vinden,
                                    probeer het nog eens of neem contact met ons op <Link link={{href: "mailto:bekeuringen@vwpfs.nl"}}>bekeuringen@vwpfs.nl</Link>
                                    </>
                                )}
                            </Blockquote>
                        </Div>
                    )}
                    {this.props.loadingDamagePDF ?
                        (
                            <LoadingIndicator
                                type={LoadingIndications.DEFAULT}
                                theme={{ palette: ThemePalette.BRAND_PRIMARY }}
                            />
                        ) :
                        (!this.props.results || this.props.results.statusCode !== 200) && (
                            <>
                                <FormFields.FormField
                                    type="licensePlate"
                                    label="Kenteken"
                                    placeholder="CLD-000-X"
                                    onChange={(value?: string | number) =>
                                        this.setState({ licensePlate: value?.toString() ?? "" },
                                        )}
                                    value={isValidLicensePlate(this.state.licensePlate)
                                        ? formatLicensePlate(this.state.licensePlate)
                                        : this.state.licensePlate.toUpperCase()}
                                    validationFunction={
                                        (value: string | number) =>
                                            hasLicensePlateValidation(value,
                                                "Kenteken ingevuld")}
                                />
                                <FormFields.FormField
                                    label="Beschikkingsnummer"
                                    onChange={(value?: string | number) =>
                                        this.setState({ dispositionNumber: value?.toString() ?? "" })}
                                    value={this.state.dispositionNumber}
                                    validationFunction={
                                        (value: string | number) =>
                                            hasValueValidation(value,
                                                "Beschikkingsnummer ingevuld")}
                                />
                                <ButtonsWrapper>
                                    <Button
                                        className="scl-a-btn--big"
                                        theme={{
                                            palette: ThemePalette.BRAND_ACCENT,
                                        }}
                                        link={{
                                            onClick: () =>
                                                this.props.fileDownload(
                                                    parseLicenseplate(this.state.licensePlate) ?? this.state.licensePlate.toUpperCase(),
                                                    this.state.dispositionNumber),
                                        }}
                                        disabled={!this.state.licensePlate || !this.state.dispositionNumber}
                                    >{ I18n.getText("app.search", "Zoeken")}
                                    </Button>
                                </ButtonsWrapper>
                            </>
                        )}
                </Container>
            </>
        );
    }

    private clear() {
        this.props.clearResults();
        this.setState({ licensePlate: "", dispositionNumber: "" });
    }
}

export const FineSearcher = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FineSearcherComp);
