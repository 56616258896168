/* eslint-disable */
import * as React from "react";
import ReactSelect, { OptionTypeBase } from "react-select";
import { ThemeConsumerProps } from "../../../theme/_Types";
import { asThemeConsumer } from "../../../theme/Theme";
import { joinClasses } from "../../../utils/Filters";
import { ensure } from "../../../utils/Optional";
import { sortBy } from "sort-by-typescript";

/**
 *
 */
type Value = string | number;

/**
 *
 */
export type SelectOption = Readonly<{
    label?: string;
    value: Value;
}>;

/**
 *
 */
export interface SelectProps
    extends ThemeConsumerProps {
    placeholder?: string;
    value?: Value;
    values?: string[] | SelectOption[];
    defaultValue?: string;
    disabled?: boolean;
    options: SelectOption[];
    onChange?: (value: Value) => void;
    multiple?: boolean;
    onChangeMultiSelect?: (selected: string[]) => void;
    required?: boolean;
}

/**
 *
 * @param props
 */
const SelectComp: React.FunctionComponent<SelectProps> = (props) => {
    const clazzName = joinClasses(
        props.className,
        "scl-a-select__field",
    );

    const onChange = !!props.onChange
        ?
        (evt: React.ChangeEvent<HTMLSelectElement>) => {
            evt.preventDefault();
            ensure(props.onChange)(evt.target.value);
        }
        : undefined;

    const onChangeMultiSelect = (value?: any) => {
        if (!!props.onChange) {
            const val = value as SelectOption[];
            ensure(props.onChange)(val.map(p => p.value).toString());
        }
    };

    if ((props.options && props.options.length > 1)) {
        props.options.sort(sortBy("label"));
    }

    if (!!props.placeholder
        && (props.options && props.options.length > 1)
        && !props.options.find((option) => option.label === props.placeholder)) {
        props.options.unshift({
            label: props.placeholder,
            value: "",
        });
    }

    return (
        <div className={clazzName}>
            {!!props.multiple ? (
                <ReactSelect
                    className="scl-a-select__field-multi"
                    classNamePrefix="scl-a-select__field-multi-sub"
                    options={props.options.filter(p => p.label !== props.placeholder)}
                    isMulti
                    placeholder={props.placeholder}
                    onChange={onChangeMultiSelect}
                    value={props.values as OptionTypeBase}
                    theme={theme => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary: "#1D1F20",
                            primary75: "#FFFFFF",
                            primary50: "#F0F1F3",
                            primary25: "#E1E5EA",
                            danger: "#fff",
                            dangerLight: "#ff4d4f",
                        },
                    })}
                />
            ) : (
                <select
                    disabled={!!props.disabled || (props.options && props.options.length <= 1)}
                    placeholder={props.placeholder}
                    onChange={onChange}
                    value={props.value || ""}
                    defaultValue={props.defaultValue}
                >
                    {props.options.map(opt => (
                        <option
                            key={opt.value}
                            value={opt.value}
                        >
                            {!!opt.label ? opt.label : opt.value}
                        </option>
                    ))}
                </select>
            )}
        </div>
    );
};

/**
 *
 */
export const Select = asThemeConsumer(SelectComp);
