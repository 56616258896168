import * as React from "react";

import { ThemeConsumerProps, ThemePropsMapper, ThemeProviderProps } from "../../../theme/_Types";
import { asThemeConsumer } from "../../../theme/Theme";
import { joinClasses } from "../../../utils/Filters";
import { Icon, IconNames } from "./Icon";
import { LinkProps, isLinkPropsHref } from "./Link";

/**
 *
 */
export interface ButtonThemeProps
    extends ThemeProviderProps {
}

/**
 *
 * @param theme
 */
const mttc: ThemePropsMapper<ButtonThemeProps> =
    (theme) =>
        joinClasses(
            theme && (theme.paletteState || theme.palette) ? "scl-a-btn" : undefined,
        );

/**
 *
 */
export type ButtonProps = ThemeConsumerProps & {
    icon?: IconNames | string;
    label?: string;
    disabled?: boolean;
    buttonType?: "submit" | "reset" | "button";
    link?: LinkProps;
    id?: string;
};

/**
 *
 * @param props
 */
const ButtonComp: React.FunctionComponent<ButtonProps> = props => {
    const clazzName = joinClasses(
        props.className,
        !!props.icon ? "scl-a-btn__icon" : undefined,
    );

    const btnIcon = !!props.icon ? <Icon name={props.icon} /> : undefined;
    const btnLabel = props.label ? props.label : undefined;

    const btnContent = () => (
        (!!btnIcon || btnLabel
            ? (
                <React.Fragment>
                    {btnIcon}
                    {btnLabel}
                </React.Fragment>
            )
            : props.children
        )
    );

    return isLinkPropsHref(props.link)
        ? (
            <a style={props.style} className={clazzName} href={props.link.href} target={props.link.target} id={props.id}
                download={props.link.download}>
                {btnContent()}
            </a>
        )
        :
        (
            <button
                id={props.id}
                style={props.style}
                type={props.buttonType ? props.buttonType : "button"}
                className={clazzName}
                onClick={!!props.link ? props.link.onClick : undefined}
                disabled={props.disabled}
            >
                {btnContent()}
            </button>
        );
};

/**
 *
 */
export const Button = asThemeConsumer(ButtonComp, mttc);
